import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import React, { FC, DragEvent, ChangeEvent, useRef, useState } from "react"

import { create } from "src/helpers/bem"
import { deleteBlob, postBlob } from "src/queriesXRM/file"
import { addNotification } from "src/states/notifications"

import { useCart } from "src/states/shop/carts"
import { useProductAttributes } from "src/states/shop/productAttributes"
import { TranslationMessages } from "src/translations"

import { xRMApiBlobStorageType, xRMApiFile } from "src/types/xRM"

export type BlobStorageType = xRMApiBlobStorageType

export type FileUploadProps = {
  id: string
  required?: boolean
  messages?: TranslationMessages
  name: string
  label?: string
  helperText?: string
  value?: xRMApiFile[]
  disabled?: boolean
  maxFiles?: number
  maxFileSize?: number // Filesize should be in bytes
  acceptedTypes?: string
  blobStorageType: BlobStorageType
  onFileUpload: (name: string, e: xRMApiFile[]) => void
  onDeleteFile?: (name: string, e: xRMApiFile[]) => void
  onButtonClick?: () => void
}

import styles from "./FileUpload.module.scss"

const bem = create(styles, "FileUpload")
const authorizationRequestsContainerName = "authorization-requests"

export const FileUpload: FC<FileUploadProps> = ({
  id,
  required,
  messages,
  value = [],
  name,
  helperText,
  label,
  blobStorageType,
  disabled = false,
  maxFiles = 5,
  maxFileSize = 5242880, // 5 MB in binary
  acceptedTypes = ".pdf, .jpg, .jpeg, .png",
  onFileUpload,
  onDeleteFile,
  onButtonClick,
}) => {
  const [inputKey, setInputKey] = useState(Math.random())
  const [dragActive, setDragActive] = useState(false)
  const m = messages?.components.common.fields.fileUpload
  const inputRef = useRef<HTMLInputElement>(null)

  const cart = useCart()
  const { product } = useProductAttributes()

  const validateFile = (filesToAdd: FileList | null) => {
    if (filesToAdd === null) {
      return false
    }
    // Add a buffer of 5% to the allowed max file size, so all files showing the max size are accepted
    const buffer = maxFileSize * 0.05
    const allowedMaxFileSize = maxFileSize + buffer

    if (maxFiles < (value ? value?.length : 0) + filesToAdd.length) {
      addNotification({
        autoclose: true,
        variant: "warning",
        content: m?.validation.tooManyFiles,
      })

      return false
    }

    for (let i = 0; i < filesToAdd.length; i++) {
      const file = filesToAdd[i]

      if (file.size > allowedMaxFileSize) {
        addNotification({
          autoclose: true,
          variant: "warning",
          content: `${m?.validation.maxFileSize1} ${fileSizeToDisplay(
            maxFileSize,
          )} ${m?.validation.maxFileSize2}`,
        })
        return false
      }

      const fileType = `.${file.name?.toLowerCase().split(".").pop()}`
      if (!acceptedTypes.includes(fileType)) {
        addNotification({
          autoclose: true,
          variant: "warning",
          content: `${m?.validation.fileType1} ${acceptedTypes}${m?.validation.fileType2}`,
        })
        return false
      }

      for (let j = 0; j < value?.length; j++) {
        if (value[j].name === file.name) {
          addNotification({
            autoclose: true,
            variant: "warning",
            content: `${m?.validation.fileNameAlreadyExists}`,
          })
          return false
        }
      }
    }

    return true
  }

  const uploadFiles = async (files: FileList) => {
    if (!validateFile(files) || !files || files?.length <= 0) {
      return
    }
    try {
      if (id) {
        const uploadedFiles = [] as xRMApiFile[]
        for (let i = 0; i < files.length; i++) {
          const file = files?.[i]
          const filename =
            blobStorageType === "Attachment"
              ? product
                ? cart.orderId + "/" + file?.name
                : file?.name
              : id + "/" + file?.name
          const metadata: { [k: string]: string } =
            blobStorageType === "Attachment"
              ? product
                ? {
                    category: "Webshop",
                    order: cart.orderId,
                    product: product.id!,
                    productCategory: product.parentCategoryId!,
                  }
                : {
                    category: "Form",
                    control: name,
                  }
              : {
                  category: "Account",
                  incidentId: id,
                }
          const uploadedFile =
            blobStorageType === "Attachment"
              ? await postBlob(id, file, filename, metadata, blobStorageType)
              : await postBlob(authorizationRequestsContainerName, file, filename, metadata, blobStorageType)
          uploadedFiles.push({
            ...uploadedFile,
            name: file?.name,
            size: file?.size?.toString(),
          })
        }
        return uploadedFiles
      }
      return
    } catch (err) {
      addNotification({
        autoclose: true,
        variant: "error",
      })
      return
    }
  }

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setInputKey(Math.random())
    setDragActive(false)
    const newFiles = (await uploadFiles(e.dataTransfer.files)) as xRMApiFile[]
    if (!newFiles) {
      return
    }
    let updatedFiles = newFiles as xRMApiFile[]
    if (value && value.length > 0) {
      updatedFiles = [...value, ...newFiles]
    }
    onFileUpload(name, JSON.parse(JSON.stringify(updatedFiles)))
  }

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setInputKey(Math.random())
    const filesToUpload = e.target.files
    if (!filesToUpload) {
      return
    }
    const newFiles = (await uploadFiles(filesToUpload)) as xRMApiFile[]
    if (!newFiles) {
      return
    }
    let updatedFiles = newFiles as xRMApiFile[]
    if (value && value.length > 0) {
      updatedFiles = [...value, ...newFiles]
    }
    onFileUpload(name, JSON.parse(JSON.stringify(updatedFiles)))
  }

  const handleDeleteFile = async (file: xRMApiFile) => {
    if (!id || !file?.name) {
      return
    }
    try {
      const filename = product
        ? cart.orderId + "/" + file?.name
        : file?.name
      const deletedFile = await deleteBlob(id, filename, blobStorageType)
      if (!deletedFile) {
        return
      }
      const filteredFiles = value.filter((el) => el.name !== file?.name)
      onDeleteFile?.(name, JSON.parse(JSON.stringify(filteredFiles)))
    } catch (err) {
      addNotification({
        autoclose: true,
        variant: "error",
      })
      return
    }
  }

  const handleButtonClick = () => {
    inputRef?.current?.click()
    onButtonClick?.()
  }

  const fileSizeToDisplay = (fileSize: number) => {
    const k = 1024 // bytes
    const i = 2 // represents MB
    return (fileSize / Math.pow(k, i)).toFixed(0)
  }
  const maxFilesReached = maxFiles <= value?.length
  const modifier = {
    "is-active": dragActive,
    "is-disabled": disabled || maxFilesReached,
  }
  return (
    <div className={bem()} onDragEnter={handleDrag}>
      {label && (
        <span className={bem("title")}>
          {label} {required ? "*" : ""}
        </span>
      )}
      <input
        key={inputKey}
        ref={inputRef}
        accept={acceptedTypes}
        className={bem("input")}
        disabled={disabled || maxFilesReached}
        id={`${name}-file-upload`}
        multiple={true}
        type="file"
        onChange={handleChange}
      />
      <label className={bem("label", modifier)} htmlFor={`${name}-file-upload`}>
        <div>
          <p className={bem("description")}>
            {maxFilesReached ? m?.maxFilesReached : m?.description}
          </p>
          <button
            className={bem("button", modifier)}
            disabled={disabled || maxFilesReached}
            onClick={handleButtonClick}
          >
            {m?.button}
          </button>
        </div>
        {maxFiles ? (
          <span className={bem("fileLength")}>
            {value.length} / {maxFiles}
          </span>
        ) : null}
      </label>
      {dragActive && !maxFilesReached && !disabled && (
        <div
          className={bem("drag")}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
      <div className={bem("fileRestrictions")}>
        <p>
          {`${m?.maxFileSizePerFile1} 
            ${fileSizeToDisplay(maxFileSize)}
            ${m?.maxFileSizePerFile2}`}
        </p>
        <p>{`${m?.supportedFileType1} 
            ${acceptedTypes}`}</p>
      </div>
      {value.length > 0 ? (
        <div className={bem("file__wrapper")}>
          {value.flatMap((file: xRMApiFile, i) => (
            <div key={`${name}-file-${i}`} className={bem("file")}>
              <span className={bem("file__name")}>{file?.name}</span>
              <div>
                <span className={bem("file__name")}>
                  {file?.size
                    ? `${Math.round(
                        Number(file?.size ?? 0) / Math.pow(1024, 1),
                      )} KB`
                    : "0 KB"}
                </span>
                <IconButton
                  aria-label="close"
                  color="error"
                  disabled={disabled}
                  size="small"
                  onClick={() => handleDeleteFile(file)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {helperText && <span className={bem("helperText")}>{helperText}</span>}
    </div>
  )
}
