import HighlightOff from "@mui/icons-material/HighlightOff"
import LinearProgress from "@mui/material/LinearProgress"
import { SelectChangeEvent } from "@mui/material/Select"

import React, { FC, useEffect, useState, useMemo } from "react"

import { Table, TableData } from "src/components/common/table"

import { create } from "src/helpers/bem"

import {
  getPermissionBySalesorderId,
  postPermission,
  deletePermission,
} from "src/queriesXRM/permission"
import { useBooking } from "src/states/bookingDetail"
import {
  useBookingDetailPermissions,
  mergeBookingDetailPermission,
  mergeBookingDetailPermissions,
  removeBookingDetailPermission,
} from "src/states/bookingDetailPermissions"

import { TranslationMessages } from "src/translations"
import { xRMApiPermissionRole } from "src/types/xRM"

import styles from "./PermissionTable.module.scss"
import { PermissionTableActions } from "./PermissionTableActions"

const bem = create(styles, "PermissionTable")

export type PermissionTableProps = {
  messages: TranslationMessages
}

export const PermissionTable: FC<PermissionTableProps> = ({ messages }) => {
  const m = messages.pages.booking.detail.actions.permissions
  const [loading, setLoading] = useState(true)
  const { booking } = useBooking()
  const { permissions } = useBookingDetailPermissions()

  const getPermissions = () => {
    getPermissionBySalesorderId(booking?.id).then((res) => {
      if (Array.isArray(res)) {
        mergeBookingDetailPermissions({
          permissions: res,
        })
      }
    })
    setLoading(false)
  }

  useEffect(() => {
    if (booking?.exhibitorStandType !== "Co") {
      setLoading(true)
      getPermissions()
    }
  }, [booking?.id])

  const handleSearchTable = (value: string, data: TableData[]) => {
    const Value = value.toLowerCase()
    return data.filter((item) => {
      if (!Value) return true
      if (
        item.name?.toLowerCase()?.includes(Value) ||
        (item.email as string)?.toLowerCase()?.includes(Value) ||
        (typeof item?.name === "string" &&
          item?.name?.toLowerCase()?.includes(Value))
      ) {
        return true
      }
    })
  }

  const handleChangeSelectField = async (e: SelectChangeEvent<unknown>) => {
    setLoading(true)
    const splittedName = e?.target?.name?.split("_")
    const email = splittedName[0]
    const id = splittedName[1]
    const role = e?.target?.value as xRMApiPermissionRole
    await postPermission({
      id,
      email,
      salesorderId: booking?.id ?? undefined,
      role,
    })
      .then((res) => {
        setLoading(false)
        mergeBookingDetailPermission(id, res)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleDeleteRole = async (e: TableData) => {
    setLoading(true)
    await deletePermission(e.id as string)
      .then(() => {
        removeBookingDetailPermission(e.id as string)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const formatedPermissions = useMemo(
    () =>
      permissions.map((el) => ({
        id: el.id,
        name: el.name ?? "-",
        email: el.email,
        role: el.role,
        emailForeignAccount : el.emailForeignAccount ?? "-",
      })),
    [permissions],
  ) as TableData[]

  if (loading) {
    return <LinearProgress color="primary" />
  }
  return (
    <div className={bem()}>
      <PermissionTableActions messages={messages} />
      {formatedPermissions && formatedPermissions.length > 0 && (
        <Table
          showAvatar
          cellTranslations={m.table}
          data={formatedPermissions}
          handleSearch={handleSearchTable}
          messages={messages}
          order="desc"
          orderBy="name"
          iconActions={[
            {
              icon: <HighlightOff />,
              color: "error",
              onClick: handleDeleteRole,
            },
          ]}
        />
      )}
    </div>
  )
}
