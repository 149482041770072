import xrmApi from "src/services/xrmApi"

import { xRMApiCheckoutPermission, xRMApiPurchaseorder, xRMApiPurchaseorderPermission } from "src/types/xRM"

const purchaseorderEndpoint = "/purchaseorder"
const purchaseorderPermissionEndpoint = "/purchaseorderpermission"

export const postPurchaseorder = (data: xRMApiPurchaseorder) => xrmApi
    .post(purchaseorderEndpoint, data, {
      // The API returns a 409 conflict if the cart has to be refreshed and
      // in that case we do not want to reject the promise but resolve it
      // and report that information via `mustRefresh`.
      validateStatus: status => (status >= 200 && status < 300) || status === 409
    })
    .then(res => ({
      mustRefresh: res.status === 409
    }))

export const postPurchaseorderRefresh = (data: xRMApiPurchaseorder) : Promise<xRMApiPurchaseorder> => xrmApi
  .post(`${purchaseorderEndpoint}/refresh`, data)
  .then(res => res?.data ?? null)

export const postPurchaseorderPermission = (data: xRMApiPurchaseorderPermission) => xrmApi
  .post(purchaseorderPermissionEndpoint, data)
  .then(async res => res?.data ?? null)

export const getPurchaseorderPermissions = (): Promise<xRMApiPurchaseorderPermission[]> => xrmApi
  .get(`${purchaseorderPermissionEndpoint}?exhibitionId=${process.env.NEXT_PUBLIC_XRM_EVENT_ID}`)
  .then(res => res?.data ?? [])

export const fetchBillingOptions = (salesorderId: string): Promise<{ checkoutPermission: xRMApiCheckoutPermission }> => xrmApi
  .get(`${purchaseorderPermissionEndpoint}/checkoutPermission?salesorderId=${salesorderId}`)
  .then(res => res?.data ?? "None")

export const fetchWebshopOrders = (): Promise<xRMApiPurchaseorder[]> => xrmApi
	.get(`${purchaseorderEndpoint}?exhibitionId=${process.env.NEXT_PUBLIC_XRM_EVENT_ID}`)
	.then(res => res?.data ?? [])

export const fetchWebshopOrder = (order: Pick<xRMApiPurchaseorder, 'id'>): Promise<xRMApiPurchaseorder> => xrmApi
    .get(`${purchaseorderEndpoint}/details?purchaseorderId=${order.id}`)
    .then(res => res.data ?? null)