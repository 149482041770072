import { SelectChangeEvent } from "@mui/material/Select"

import React, { FC, ChangeEvent, SyntheticEvent } from "react"

import { Autocomplete } from "src/components/common/autocomplete"
import { Checkbox } from "src/components/common/checkbox"
import { ChipItem } from "src/components/common/chips"
import { Content } from "src/components/common/content"
import { DatePicker } from "src/components/common/datepicker"
import { Download } from "src/components/common/download"
import { FileUpload } from "src/components/common/fileUpload"
import {
  InputField,
  FieldType as InputFieldType,
} from "src/components/common/inputfield/InputField"
import { Radio } from "src/components/common/radio"
import { Select, SelectOption } from "src/components/common/select"
import { TreeViewItemProps } from "src/components/common/treeView"

import { TranslationMessages } from "src/translations"

import { xRMApiDependency, xRMApiFile } from "src/types/xRM"

import { AdvancedFormCondition } from "./AdvancedForm"
import { AdvancedFormFieldContainer } from "./AdvancedFormFieldContainer"
import { AdvancedFormFieldTreeView } from "./AdvancedFormFieldTreeView"

export type FieldType =
  | "radio"
  | "select"
  | "autocomplete"
  | "date"
  | "file"
  | "fileAuthorization"
  | "checkbox"
  | "country"
  | "region"
  | "languages"
  | "academicTitle"
  | "companyType"
  | "nomenclature"
  | "download"
  | "content"
  | "ustDeclarations"
  | InputFieldType

export type FieldValueObject = {
  key: FieldValue
  value: FieldValue
}

export type FieldValue =
  | boolean
  | string
  | string[]
  | number
  | xRMApiFile[]
  | FieldValueObject

export type AdvancedFormFieldDependency = xRMApiDependency

export interface AdvancedFormFieldProps {
  id?: string
  field?: string
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  multiple?: boolean
  position?: number
  type?: FieldType
  label?: string
  value?: FieldValue
  name?: string
  description?: string
  disabled?: boolean
  required?: boolean
  items?: SelectOption[]
  helperText?: string
  errorText?: string
  conditions?: AdvancedFormCondition
  defaultConditions?: AdvancedFormCondition
}

export type AdvancedFormFieldComponentProps = {
  messages?: TranslationMessages
  updateFieldState: (
    value: AdvancedFormFieldProps,
    name?: string,
    type?: FieldType,
  ) => void
} & AdvancedFormFieldProps

export const AdvancedFormField: FC<AdvancedFormFieldComponentProps> = ({
  id,
  type,
  name,
  field,
  value,
  items,
  conditions,
  description,
  updateFieldState,
  ...props
}) => {
  const handleValidation = (error?: string) => {
    updateFieldState(
      {
        errorText: error,
      } as AdvancedFormFieldProps,
      name,
    )
  }

  const handleChangeTextField = async (e: ChangeEvent<HTMLInputElement>) => {
    updateFieldState(
      {
        value: e.target.value,
      } as AdvancedFormFieldProps,
      e?.target?.name,
    )
  }

  const handleChangeSelectField = (e: SelectChangeEvent<unknown>) => {
    updateFieldState(
      {
        value: e.target.value,
      } as AdvancedFormFieldProps,
      e?.target?.name,
    )
  }

  const handleChangeDatePicker = (date: Date | null, name?: string) => {
    if (name) {
      updateFieldState(
        {
          value: date?.toISOString(),
        } as AdvancedFormFieldProps,
        name,
      )
    }
  }

  const handleChangeCheckboxField = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateFieldState(
      {
        value: e.target?.checked,
      } as AdvancedFormFieldProps,
      e.target?.name,
    )
  }

  const handleChangeAutocompleteField = (
    e: SyntheticEvent,
    item?: SelectOption | string | null,
  ) => {
    if (typeof item !== "string") {
      updateFieldState(
        {
          value: item?.key,
        } as AdvancedFormFieldProps,
        e.type,
      )
    }
  }

  const handleValidationAutocomplete = (e: SyntheticEvent, error?: string) => {
    updateFieldState(
      {
        errorText: error,
      } as AdvancedFormFieldProps,
      e.type,
    )
  }

  const handleChangeFiles = async (name: string, files: xRMApiFile[]) => {
    updateFieldState(
      {
        value: files,
      } as AdvancedFormFieldProps,
      name,
    )
  }

  const handleValidationTreeView = (valueLength: number) => {
    const { messages, required, min, max } = props
    if (required && valueLength <= 0) {
      return (
        messages?.components?.common?.fields?.required ??
        "This field is required!"
      )
    }
    if (!!min && valueLength < min) {
      return `${messages?.components.common.fields.inputField.numericMin1} ${min} ${messages?.components.common.fields.inputField.numericMin2}`
    }
    if (!!max && valueLength > max) {
      return `${messages?.components.common.fields.inputField.numericMax1} ${max} ${messages?.components.common.fields.inputField.numericMax2}`
    }
    return undefined
  }

  const handleDeleteTreeViewItem = (item: ChipItem | TreeViewItemProps) => {
    const valueLength = (value as string[])?.length - 1
    const errorText = handleValidationTreeView(valueLength)
    const filteredValues = (value as string[])?.filter((el) => el !== item?.id)
    updateFieldState(
      {
        value: filteredValues,
        errorText,
      } as AdvancedFormFieldProps,
      name,
    )
  }

  const handleAddTreeViewItem = (item: TreeViewItemProps) => {
    if ((value as string[])?.includes(item?.id ?? "")) {
      return handleDeleteTreeViewItem(item)
    }
    const valueLength = (value as string[])?.length + 1
    const errorText = handleValidationTreeView(valueLength)
    updateFieldState(
      {
        value: value ? [...(value as string[]), item?.id] : [item?.id],
        errorText,
      } as AdvancedFormFieldProps,
      name,
    )
  }

  const ContainerProps = {
    field,
    conditions,
    description,
  }
  switch (type) {
    case "text":
    case "email":
    case "zip":
    case "houseNumber":
    case "tel":
    case "number":
    case "password":
    case "url":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <InputField
            {...props}
            name={name}
            type={type}
            value={value}
            onChange={handleChangeTextField}
            onValidate={handleValidation}
          />
        </AdvancedFormFieldContainer>
      )
    case "languages":
    case "radio":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <Radio
            {...props}
            row
            items={items}
            name={name}
            value={value}
            onChange={handleChangeSelectField}
            onValidate={handleValidation}
          />
        </AdvancedFormFieldContainer>
      )
    case "companyType":
    case "ustDeclarations":
    case "select":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <Select
            {...props}
            items={items}
            name={name}
            value={value}
            onChange={handleChangeSelectField}
            onValidate={handleValidation}
          />
        </AdvancedFormFieldContainer>
      )
    case "checkbox":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <Checkbox
            {...props}
            checked={!!value}
            name={name}
            onChange={handleChangeCheckboxField}
          />
        </AdvancedFormFieldContainer>
      )
    case "academicTitle":
    case "country":
    case "region":
    case "autocomplete":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <Autocomplete
            {...props}
            multiple={undefined}
            name={name ?? ""}
            noOptionsText={
              props.messages?.components.common.fields.autocomplete.noOptions
            }
            options={items ?? []}
            value={value as SelectOption | null | undefined}
            onChange={handleChangeAutocompleteField}
            onValidate={handleValidationAutocomplete}
          />
        </AdvancedFormFieldContainer>
      )
    case "date":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <DatePicker
            {...props}
            name={name ?? ""}
            value={value && typeof value === "string" ? new Date(value) : null}
            onChange={handleChangeDatePicker}
          />
        </AdvancedFormFieldContainer>
      )
    case "file":
    case "fileAuthorization":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <FileUpload
            {...props}
            blobStorageType={type === "file" ? "Attachment" : "Webshop"}
            id={id ?? ""}
            name={name ?? ""}
            value={value as xRMApiFile[]}
            onDeleteFile={handleChangeFiles}
            onFileUpload={handleChangeFiles}
          />
        </AdvancedFormFieldContainer>
      )
    case "nomenclature":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <AdvancedFormFieldTreeView
            {...props}
            buttonLabel={props?.messages?.forms?.nomenclature as string}
            handleAddElement={handleAddTreeViewItem}
            handleDeleteElement={handleDeleteTreeViewItem}
            items={items}
            value={value as string[]}
            onValidate={handleValidation}
          />
        </AdvancedFormFieldContainer>
      )
    case "download":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <Download items={items} />
        </AdvancedFormFieldContainer>
      )
    case "content":
      return (
        <AdvancedFormFieldContainer {...ContainerProps}>
          <Content
            content={value as string}
            helperText={props.helperText}
            label={props.label}
          />
        </AdvancedFormFieldContainer>
      )
    default:
      return null
  }
}
