import {
  AnonymousCredential,
  BlobDeleteOptions,
  BlockBlobParallelUploadOptions,
  ContainerClient,
} from "@azure/storage-blob"

import { BlobStorageType } from "src/components/common/fileUpload"

import xrmApi from "src/services/xrmApi"

// AzureAccountLocation/+containerId+fileName+sasToken
export async function getBlobUri(
  containerName: string,
  fileName: string,
  containerId: string,
  blobStorageType: BlobStorageType,
  storage?: string,
  target?: string,
  features?: string,
) {
  const sasToken = await getSasToken(containerId, blobStorageType)
  window.open(
    `${
      storage ?? blobStorageType === "Attachment" ? process.env.NEXT_PUBLIC_BLOB_STORAGE : process.env.NEXT_PUBLIC_WEBSHOP_BLOB_STORAGE
    }${containerName}${fileName}${sasToken}`,
    target,
    features,
  )
}

export const getSasToken = async (containerId: string, blobStorageType: BlobStorageType) => {
  return xrmApi
    .get(`/Blob/token?containerId=${containerId}&blobStorageType=${blobStorageType}`)
    .then((res) => res?.data.token)
}

function getContainerClient(containerName: string, sas: string, blobStorageType: BlobStorageType) {
  // Anonymous access is forbidden for our blob storage.
  // Nevertheless, AnonymousCredential is used when using sas tokens, see https://github.com/Azure/azure-sdk-for-js/tree/main/sdk/storage/storage-blob#with-sas-token
  return new ContainerClient(
    getContainerUri(containerName, sas, blobStorageType),
    new AnonymousCredential(),
  )
}

// AzureAccountLocation/+containerId+sasToken
function getContainerUri(containerName: string, sas: string, blobStorageType: BlobStorageType): string {
  if(blobStorageType === "Attachment")
  {
    return `${process.env.NEXT_PUBLIC_BLOB_STORAGE}/${containerName}${sas}`
  }
  else
  {
    return `${process.env.NEXT_PUBLIC_WEBSHOP_BLOB_STORAGE}/${containerName}${sas}`
  }
}

export const postBlob = async (
  containerId: string,
  file: Blob,
  fileName: string,
  metadata: { [k: string]: string },
  blobStorageType: BlobStorageType

) => {
  const options: BlockBlobParallelUploadOptions = {
    maxSingleShotSize: 4 * 1024 * 1024,
    blobHTTPHeaders: { blobContentType: file.type },
    metadata
  }

  // New SAS token
  const azureToken = await getSasToken(containerId, blobStorageType)

  // Contains the destination (folder)
  const containerClient = getContainerClient(containerId, azureToken, blobStorageType)

  // Handles the following upload
  const blockBlobClient = containerClient.getBlockBlobClient(fileName)

  // Upload
  if (file != null) {
    return blockBlobClient.uploadData(file, options)
  }
}

export const deleteBlob = async (containerId: string, fileName: string, blobStorageType: BlobStorageType) => {
  // New SAS token
  const azureToken = await getSasToken(containerId, blobStorageType)

  // Contains the destination (folder)
  const containerClient = getContainerClient(containerId, azureToken, blobStorageType)

  // Handles the following upload
  const blockBlobClient = containerClient.getBlockBlobClient(fileName)

  const options: BlobDeleteOptions = {
    deleteSnapshots: "include",
  }

  // Delete
  if (fileName != null) {
    return blockBlobClient.deleteIfExists(options)
  }
}

export const getFilesById = async ( blobStorageType: BlobStorageType, id?: string | null,) => {
  if (!id) {
    return null
  }
  return xrmApi.get(`/file?fileId=${id}&blobStorageType=${blobStorageType}`).then(async (res) => {
    if (res?.data) {
      return res.data
    }
    return null
  })
}

export const deleteFileById = async (
  blobStorageType: BlobStorageType,
  id?: string | null,
  containerId?: string | null,
) => {
  if (!id || !containerId) {
    return null
  }
  return xrmApi
    .delete(`/file?fileId=${id}&containerId=${id}&blobStorageType=${blobStorageType}`)
    .then(async (res) => {
      if (res?.data) {
        return res.data
      }
      return null
    })
}
